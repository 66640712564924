<template>
    <div class="pwd">
        <el-form :model="ruleForm" status-icon :rules="rules"  ref="ruleForm" class="demo-ruleForm">
            <el-form-item prop="oldPass">
                <span>旧密码</span>
                <br>
                <el-input v-model="ruleForm.oldPass" placeholder="请输入旧密码" type="password" show-password></el-input>
            </el-form-item>
            <el-form-item prop="pass">
                <span>新密码</span>
                <br>
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
                <span>确认新密码</span>
                <br>
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
        </el-form>
        <div class="btn">
            <button @click="submitForm('ruleForm')">确定</button>
        </div>
    </div>
</template>

<script>
import {updatePwd} from '@/api/personal'
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                pass: '',
                checkPass: '',
                oldPass: ''
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                oldPass: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' }
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async(valid) => {
                if (valid) {
                    // alert('submit!');
                    const res = await updatePwd({id: Number(JSON.parse(localStorage.getItem('userInfo')).id), oldPassword: this.ruleForm.oldPass,password: this.ruleForm.checkPass});
                    console.log(res);
                    if(res.code === 200) {
                         this.$message({
                            showClose: true,
                            type: 'success',
                            message: '修改成功！请重新登录'
                        });
                        localStorage.clear();
                        setTimeout(() => {
                            this.$router.push('/')
                        }, 2500);
                    } else {
                         this.$message({
                            showClose: true,
                            message: '修改失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="scss" scoped>
.pwd {
    padding: 40px 0px;
    width: 80%;
    margin: auto;
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    .el-form {
        // height: 100%;
        span {
            font-size: 16px;
        }

        .el-select,
        .el-date-picker,
        .el-input {
            width: 100%;
            // height: 40px !important;
            // line-height: 40px !important;

        }
    }

    .btn {
        margin-top: 40px;

        button {
            cursor: pointer;
            width: 100%;
            height: 40px;
            background: var(--custom-color);
            border-radius: 4px;
            border: none;
            color: white;
            font-size: 14px;
        }
    }
}
</style>